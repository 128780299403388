import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-enterprise';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.scss';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Api from '../../../axios/customAxios';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import EmptyTable from '../../common/aggrid/EmptyTable';

import 'react-toastify/dist/ReactToastify.css';
import FilterPanel from './FilterPanel';
import { FILTER_TYPE, FILTER_OPERATOR } from '../../../constants';
import useFetch from '../../../hooks/useFetch';
import { getPicklistOptions } from '../../../utils/portfolioIntelligence';

const handleNotification = (message, isSuccess) => {
  if (isSuccess) {
    toast.success(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  } else {
    toast.error(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  }
};

const getTimestamp = () => {
  const now = new Date();

  return now
    .toISOString()
    .replace(/[:\-T.]/g, '')
    .slice(0, 14);
};

const downloadFile = async (url, filename) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);

    const sanitizedFilename = `${filename}`;

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = sanitizedFilename;
    link.click();
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

const zipFiles = async (fileData, currentPath) => {
  const zip = new JSZip();
  const zipFilename = `yellowpad_${getTimestamp()}.zip`;

  for (const file of fileData) {
    const filePath = `${currentPath}${file.name}`;

    if (file.type === 'FOLDER') {
      zip.folder(file.name);
    } else {
      try {
        const userId = localStorage.getItem('userId');
        let customer;
        if (userId === 'be4030b1-c840-4164-8e5b-ba944f5399c3')
          customer = 'Cresta';
        else customer = 'Lucid';
        const response = await Api.getFile(
          filePath,
          localStorage.getItem(''),
          customer
        );

        if (response && response.s3url) {
          const fileUrl = response.s3url.split('?')[0];
          const fileName = file.name;
          const fileBlob = await fetch(fileUrl).then((res) => res.blob());

          zip.file(fileName, fileBlob);

          const content = await zip.generateAsync({ type: 'blob' });
          saveAs(content, zipFilename);
        }
      } catch (error) {
        console.error(`Error fetching file for zipping: ${filePath}`, error);
      }
    }
  }
};

const HANDLED_TERM_FILTERS = ['include', 'governing law'];

const getTermFilterType = (term, termValues) => {
  const keys = Object.keys(termValues);
  if (keys.some((key) => key.toLowerCase().includes('include'))) {
    return FILTER_TYPE.TERM_INCLUDE;
  }
  if (term.toLowerCase().includes('governing law')) {
    return FILTER_TYPE.TERM_PICKLIST;
  }
  return null;
};

const Search = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const gridApiRef = useRef(null);
  const dropdownRef = useRef(null);

  const [currentPath, setCurrentPath] = useState(
    decodeURIComponent(searchParams.get('currentPath') || '/')
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showTrashPopup, setShowTrashPopup] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowData, setRowData] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [hoveredDropdown, setHoveredDropdown] = useState(null);
  // [{ title: string; exclude: boolean; value: T, type: FILTER_TYPE, operator: FILTER_OPERATOR }]
  // this structure assumes that each operator specifies how to connect the current filter with the previous one
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [userList, setUserList] = useState([]);
  const { data: categories } = useFetch(Api.getDocumentCategories);
  //  {
  //      [term: string]:
  //        values: {
  //          [value: string]: number;
  //        },
  //        valueTypeCounts: {
  //          numbers: number;
  //          dates: number;
  //          strings: number;
  //        }
  //  }
  const { data: termCounts } = useFetch(Api.getTermCounts);
  const sortedTermOptions = Object.entries(termCounts || {})
    ?.map(([term, { valueTypeCounts }]) => ({
      term,
      sum: Object.values(valueTypeCounts).reduce((acc, val) => acc + val, 0),
    }))
    // primarly order by highest count and then by alphabetical order
    .sort((a, b) => {
      if (b.sum === a.sum) {
        return a.term.localeCompare(b.term);
      }
      return b.sum - a.sum;
    })
    .map(({ term }) => term)
    // todo: LATER REMOVE THIS FILTER when we handle all terms
    .filter((term) => {
      const termValues = termCounts[term]?.values;
      return (
        Object.keys(termValues).some((key) =>
          HANDLED_TERM_FILTERS.some((handledTerm) =>
            key.toLowerCase().includes(handledTerm.toLowerCase())
          )
        ) || HANDLED_TERM_FILTERS.includes(term.toLowerCase())
      );
    });

  const ownersOptions = userList;

  useEffect(() => {
    if (
      !selectedFilters.filter((selectedFilter) => !selectedFilter.exclude)
        .length
    ) {
      setRowData([]);
      return;
    }
    const portfolioIntelligenceSearch = async () => {
      try {
        setLoadingSearch(true);
        const response = await Api.portfolioSearch(selectedFilters);
        setRowData(response.data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingSearch(false);
      }
    };
    portfolioIntelligenceSearch();
  }, [selectedFilters]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message.startsWith('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

    fetchUserList();

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setHoveredDropdown(null);
      }
    };

    // Add event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchUserList = async () => {
    try {
      const response = await Api.getUserList();

      if (response && response.data) {
        const responseList = response.data?.users;
        setUserList(responseList);
      } else {
        setUserList([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      handleNotification('Failed to fetch users!', false);
    }
  };

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: 'Name',
      field: 'file.name',
      flex: 1,
      suppressRowClickSelection: true,
      cellClass: 'search-page-ag-row-name',
      autoHeight: true,
    },
    {
      headerName: 'Owner',
      field: 'file.owner',
      flex: 1,
      suppressRowClickSelection: true,
      cellClass: 'search-page-ag-row',
    },
    {
      headerName: 'Email',
      field: 'file.email',
      flex: 1,
      suppressRowClickSelection: true,
      cellClass: 'search-page-ag-row',
    },
    {
      headerName: 'Created',
      field: 'file.created',
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      },
    },
    {
      headerName: 'Size',
      field: 'file.size',
      flex: 1,
      valueFormatter: (params) => `${(params.value / 1024).toFixed(2)} KB`,
    },
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = async (e) => {
    if (e.key === 'Enter') {
      try {
        const response = await Api.getSearchQuery(searchQuery);
        setRowData(response.data);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }
  };

  const onRowDoubleClicked = async (params) => {
    const {
      file: { name },
      type,
    } = params.data;

    if (type === 'FOLDER') {
      setCurrentPath(`${currentPath}${name}/`);
    } else {
      try {
        window.open(
          `/dashboard/worktable/${params?.data?.file?.checklistId}?path=${currentPath}&filename=${name}`,
          '_blank'
        );
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    }
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedCount(selectedRows.length);
    setSelectedFiles(selectedRows.map((row) => row));
  };

  const handleDownloadClick = async () => {
    if (selectedFiles.length === 0) return;

    const selectedFolders = selectedFiles.filter(
      (file) => file.type === 'FOLDER'
    );
    const selectedFileNames = selectedFiles.filter(
      (file) => file.type !== 'FOLDER'
    );

    if (selectedFileNames.length === 1 && selectedFolders.length === 0) {
      const file = selectedFileNames[0]?.file;

      try {
        if (file) {
          let fileUrl = selectedFileNames[0]?.file?.s3url;
          const originalFileName = file.name;
          downloadFile(fileUrl, originalFileName);
        } else {
          console.error('Error: No data returned from API for file download.');
          alert('File could not be downloaded. Please try again.');
        }
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('File could not be downloaded due to an error.');
      }
    } else {
      await zipFiles(selectedFiles, currentPath);
    }
  };

  const handleMoveToTrashClick = () => {
    if (selectedFiles.length > 0) setShowTrashPopup(true);
  };

  const handleCancelTrash = () => {
    setShowTrashPopup(false);
    setConfirmText('');
  };

  const handleConfirmTrash = async () => {
    if (confirmText.toLowerCase() === 'confirm') {
      try {
        const userId = localStorage.getItem('userId');
        let customer;
        if (userId === 'be4030b1-c840-4164-8e5b-ba944f5399c3')
          customer = 'Cresta';
        else customer = 'Lucid';
        const filePaths = selectedFiles.map(
          (file) => `${currentPath}${file?.name}`
        );
        const username = localStorage.getItem('username');

        const promises = filePaths.map((filePath) =>
          Api.deleteFilesystemFile(filePath, username, customer)
        );

        await Promise.all(promises);
      } catch (error) {
        console.error('Error moving files to trash:', error);
      } finally {
        setShowTrashPopup(false);
        setConfirmText('');
      }
    }
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;

    const savedState = localStorage.getItem('columnState-Search');
    if (savedState) {
      gridApiRef.current.applyColumnState({
        state: JSON.parse(savedState),
        applyOrder: true,
      });
    }
  };

  const saveColumnState = () => {
    if (gridApiRef.current) {
      const columnState = gridApiRef.current.getColumnState();
      if (columnState) {
        localStorage.setItem('columnState-Search', JSON.stringify(columnState));
      }
    } else {
      console.error('Grid API is not available yet.');
    }
  };

  const handleTermClick = (term) => {
    if (!termCounts) return;
    const filterAlreadySelected = selectedFilters.some(
      (filter) => filter.title === term
    );
    if (filterAlreadySelected) return;

    const termValues = termCounts[term].values;
    const filterType = getTermFilterType(term, termValues);
    const picklistOptions = getPicklistOptions(term, termCounts);

    setSelectedFilters((prevFilters) => [
      ...prevFilters,
      {
        title: term,
        exclude: false,
        type: filterType,
        operator: FILTER_OPERATOR.AND,
        value:
          filterType === FILTER_TYPE.TERM_INCLUDE
            ? true
            : [picklistOptions[0].id],
      },
    ]);
  };

  const handleCategoryClick = (category) => {
    if (
      selectedFilters.some(
        (selectedFilter) => selectedFilter.type === FILTER_TYPE.CATEGORIES
      )
    ) {
      return;
    }
    setSelectedFilters((prevFilters) => [
      ...prevFilters,
      {
        title: 'Categories',
        value: [category.id],
        exclude: false,
        type: FILTER_TYPE.CATEGORIES,
        operator: FILTER_OPERATOR.AND,
      },
    ]);
  };

  const onCategoryChecked = ({ option: category, checked }) => {
    // must have at least one value checked if filter is selected
    if (
      !checked &&
      selectedFilters.find((filter) => filter.type === FILTER_TYPE.CATEGORIES)
        .value.length === 1
    ) {
      return;
    }
    setSelectedFilters((prevFilters) =>
      prevFilters.map((prevFilter) =>
        prevFilter.type === FILTER_TYPE.CATEGORIES
          ? {
              ...prevFilter,
              value: checked
                ? [...prevFilter.value, category.id]
                : prevFilter.value.filter(
                    (prevCategory) => prevCategory !== category.id
                  ),
            }
          : prevFilter
      )
    );
  };

  const handleOwnerClick = (owner) => {
    if (
      selectedFilters.some(
        (selectedFilter) => selectedFilter.type === FILTER_TYPE.OWNERS
      )
    ) {
      return;
    }

    setSelectedFilters((prevFilters) => [
      ...prevFilters,
      {
        title: 'Owners',
        value: [owner.id],
        exclude: false,
        type: FILTER_TYPE.OWNERS,
        operator: FILTER_OPERATOR.AND,
      },
    ]);
  };

  const onFilterOperatorChange = (filterIndex, filterOperator) => {
    setSelectedFilters((prevSelectedFilters) =>
      prevSelectedFilters.map((selectedFilter, i) =>
        // we add 1 here just so the operator refers to the previous filter
        i === filterIndex + 1
          ? { ...selectedFilter, operator: filterOperator }
          : selectedFilter
      )
    );
  };

  const onOwnerChecked = ({ option: owner, checked }) => {
    // must have at least one value checked if filter is selected
    if (
      !checked &&
      selectedFilters.find((filter) => filter.type === FILTER_TYPE.OWNERS).value
        .length === 1
    ) {
      return;
    }
    setSelectedFilters((prevFilters) =>
      prevFilters.map((prevFilter) =>
        prevFilter.type === FILTER_TYPE.OWNERS
          ? {
              ...prevFilter,
              value: checked
                ? [...prevFilter.value, owner.id]
                : prevFilter.value.filter(
                    (prevOwner) => prevOwner !== owner.id
                  ),
            }
          : prevFilter
      )
    );
  };

  const onTermIncludeChange = (termKey, checked) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.map((prevFilter) =>
        prevFilter.type === FILTER_TYPE.TERM_INCLUDE &&
        prevFilter.title === termKey
          ? { ...prevFilter, value: checked }
          : prevFilter
      )
    );
  };

  const onPicklistChecked = ({ termKey, option, checked }) => {
    if (
      !checked &&
      selectedFilters.find(
        (filter) =>
          filter.type === FILTER_TYPE.TERM_PICKLIST && filter.title === termKey
      )?.value.length === 1
    ) {
      return;
    }
    setSelectedFilters((prevFilters) =>
      prevFilters.map((prevFilter) =>
        prevFilter.type === FILTER_TYPE.TERM_PICKLIST &&
        prevFilter.title === termKey
          ? {
              ...prevFilter,
              value: checked
                ? [...prevFilter.value, option.id]
                : prevFilter.value.filter(
                    (prevOption) => prevOption !== option.id
                  ),
            }
          : prevFilter
      )
    );
  };

  const onExcludeChange = (filterIndex, exclude) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.map((filter, i) =>
        i === filterIndex ? { ...filter, exclude } : filter
      )
    );
  };

  const handleRemoveFilter = (index) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((_, i) => i !== index)
    );
  };

  // const noRowsOverlayComponentParams = useMemo(() => {
  //     return {
  //         noRowsMessageFunc: () => 'No rows found at: ' + new Date().toLocaleTimeString(),
  //     };
  // }, []);

  const toggleDropdown = (dropdown) => {
    if (searchQuery.length > 0) return;
    setHoveredDropdown((prevDropdown) =>
      prevDropdown === dropdown ? null : dropdown
    );
  };

  return (
    <div className="search-page-container">
      <ToastContainer />

      <div className="search-page-title">
        Search <i className="material-icons">search</i>
      </div>
      <div
        className="search-page-bar-container"
        style={{
          cursor: selectedFilters.length > 0 ? 'not-allowed' : 'auto',
        }}
      >
        <input
          type="text"
          className="search-page-searchbar"
          placeholder="Search anything..."
          value={searchQuery}
          disabled={selectedFilters.length > 0}
          onChange={handleSearchChange}
          onKeyDown={handleSearch}
        />
      </div>

      <div className="search-page-ag-toolbar">
        <span>{selectedCount} selected</span>
        <button
          className="search-page-ag-toolbar-button"
          disabled={selectedCount === 0}
          onClick={handleDownloadClick}
        >
          <div className="search-page-ag-toolbar-button-icon">
            <i className="material-icons">download</i>
          </div>
          <div className="search-page-ag-toolbar-button-text">Download</div>
        </button>
        <button
          className="search-page-ag-toolbar-button"
          disabled={selectedCount === 0}
          onClick={() => alert('Share clicked')}
        >
          <div className="search-page-ag-toolbar-button-icon">
            <i className="material-icons">share</i>
          </div>
          <div className="search-page-ag-toolbar-button-text">Share</div>
        </button>
        <div className="search-page-ag-dropdown-menu">
          <button
            className="search-page-ag-dropdown-menu-button"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <i className="material-icons">more_vert</i>
          </button>
          {showDropdown && (
            <div className="search-page-ag-dropdown-content">
              <div
                className="search-page-ag-dropdown-content-row"
                onClick={handleMoveToTrashClick}
              >
                <i className="material-icons">delete</i>Move to Trash
              </div>
              <div
                className="search-page-ag-dropdown-content-row"
                onClick={() => navigate('/dashboard/trash')}
              >
                <i className="material-icons">delete_forever</i>Go to Trash
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        ref={dropdownRef}
        className={[
          'search-page-dropdown-bar',
          searchQuery.length && 'disabled',
        ]
          .filter(Boolean)
          .join(' ')}
      >
        {!!sortedTermOptions.length && (
          <div className="search-page-dropdown-wrapper">
            <button
              disabled={searchQuery.length > 0}
              className="search-page-dropdown-button"
              onClick={() => toggleDropdown('terms')}
            >
              Terms
            </button>
            {hoveredDropdown === 'terms' && (
              <div className="search-page-terms-dropdown">
                {sortedTermOptions.map((termOption, index) => (
                  <div
                    key={`${termOption}-${index}`}
                    className="search-page-terms-dropdown-item"
                    onClick={() => handleTermClick(termOption)}
                  >
                    <span className="search-page-term-label">{termOption}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Categories Dropdown */}
        <div className="search-page-dropdown-wrapper">
          <button
            className="search-page-dropdown-button"
            disabled={searchQuery.length > 0}
            onClick={() => toggleDropdown('categories')}
          >
            Categories
          </button>
          {hoveredDropdown === 'categories' && categories && (
            <div className="search-page-terms-dropdown">
              {categories.map((category) => (
                <div
                  key={`category-option-${category.id}`}
                  onClick={() => handleCategoryClick(category)}
                  className="search-page-terms-dropdown-item"
                >
                  {category.label}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Owners Dropdown */}
        <div className="search-page-dropdown-wrapper">
          <button
            disabled={searchQuery.length > 0}
            className="search-page-dropdown-button"
            onClick={() => toggleDropdown('owners')}
          >
            Owners
          </button>
          {hoveredDropdown === 'owners' && (
            <div className="search-page-terms-dropdown">
              {ownersOptions.map((owner, index) => (
                <div
                  key={`owner-option-${owner.id}`}
                  onClick={() => handleOwnerClick(owner)}
                  className="search-page-terms-dropdown-item"
                >
                  {owner?.email}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="search-page-table-and-filters-container">
        <div className="search-page-filters-container">
          <FilterPanel
            selectedFilters={selectedFilters}
            onOperatorChange={onFilterOperatorChange}
            onOwnerChecked={onOwnerChecked}
            handleRemoveFilter={handleRemoveFilter}
            onExcludeChange={onExcludeChange}
            ownersOptions={ownersOptions}
            categoriesOptions={categories}
            onCategoryChecked={onCategoryChecked}
            onTermIncludeChange={onTermIncludeChange}
            termCounts={termCounts}
            onPicklistChecked={onPicklistChecked}
          />
        </div>
        <div id="test" className="ag-theme-alpine">
          <AgGridReact
            onGridReady={onGridReady}
            rowData={rowData}
            loading={loadingSearch}
            columnDefs={columnDefs}
            rowSelection="multiple"
            noRowsOverlayComponent={EmptyTable} // Pass your component directly
            noRowsOverlayComponentParams={{
              noRowsMessage: 'No Rows to Show! Search for Results!',
            }}
            onRowDoubleClicked={onRowDoubleClicked}
            animateRows={true}
            onSelectionChanged={onSelectionChanged}
            suppressRowClickSelection={true}
            onColumnMoved={saveColumnState}
            onColumnVisible={saveColumnState}
            sideBar={{
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: 'Columns',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true,
                    suppressPivots: true,
                  },
                },
              ],
            }}
          />
        </div>
      </div>

      {showTrashPopup && (
        <div className="basepopup-filesystem-overlay">
          <div className="search-page-trash-popup">
            <div className="search-page-trash-popup-content">
              <div className="search-page-trash-popup-title">
                Confirm Move to Trash
              </div>
              <div className="search-page-trash-popup-files">
                <div className="search-page-trash-popup-files-title">
                  Files:
                </div>
                <div className="search-page-trash-popup-file-list">
                  {selectedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="search-page-trash-popup-file-item"
                    >
                      {`${currentPath}${file.name}`}
                    </div>
                  ))}
                </div>
              </div>
              <div className="search-page-trash-popup-confirm">
                <div className="search-page-trash-popup-confirm-title">
                  Type "CONFIRM" to Proceed:
                </div>
                <input
                  type="text"
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                  className="search-page-trash-popup-input"
                  placeholder="CONFIRM"
                />
              </div>
              <div className="search-page-trash-popup-actions">
                <button
                  onClick={handleCancelTrash}
                  className="search-page-trash-popup-cancel-button"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmTrash}
                  className="search-page-trash-popup-confirm-button"
                  disabled={confirmText.toLowerCase() !== 'confirm'}
                >
                  Move to Trash
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
