import React from 'react';

// options => {id: string, label: string}[]
export default function ChecklistFilter({
  title: termKey,
  options,
  optionChecked,
  onCheckOption,
}) {
  return (
    <div>
      <div className="search-page-picklist-label-main">
        {options.map((option) => (
          <label
            key={`filter-option-${option.id}`}
            className="search-page-picklist-label"
          >
            <input
              checked={optionChecked(option, termKey)}
              onChange={(e) =>
                onCheckOption?.({ option, checked: e.target.checked, termKey })
              }
              type="checkbox"
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
}
