import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/auth/AuthContext';
import ProtectedRoutes from './components/auth/ProtectedRoute';
import Search from './components/pages/search/Search';
import Trash from './components/pages/trash/Trash';
import ErrorBoundary from './components/shared/ErrorBoundary';
import Loading from './components/shared/Loading/Loading';
import ScrollToTop from './components/shared/ScrollToTop';

const Layout = React.lazy(() => import('./components/layouts/PublicLayout'));
const LogInPage = React.lazy(() => import('./components/auth/preAuth/Login'));
const NotAuthorized = React.lazy(
  () => import('./components/NotAuthorized/NotAuthorized')
);
const Register = React.lazy(() => import('./components/auth/preAuth/Register'));
const Worktable = React.lazy(
  () => import('./components/pages/worktable/WorktablePage')
);
const Home = React.lazy(() => import('./components/pages/home/Home'));
const Filesystem = React.lazy(
  () => import('./components/pages/fileSystem/FileSystem')
);

export default function Routing() {
  return (
    <AuthProvider>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <ScrollToTop />
          <Routes>
            <Route path="/401" element={<NotAuthorized />} />
            <Route path="/" element={<LogInPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<ProtectedRoutes />}>
              {/* <Route path='/dashboard'> */}
              <Route path="" element={<Layout />}>
                <Route path="" element={<Home />} />
                <Route path="worktable/:id" element={<Worktable />} />
                <Route path="search" element={<Search />} />
                <Route path="filesystem" element={<Filesystem />} />
                <Route path="trash" element={<Trash />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </AuthProvider>
  );
}
