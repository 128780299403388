import { FILTER_TYPE } from '../constants';

export const getQueryPropertyType = (termFilter) => {
  if (termFilter.value === true) {
    return 'included';
  }
  if (termFilter.value === false) {
    return 'excluded';
  }
  if (termFilter.type === FILTER_TYPE.CATEGORIES) {
    return 'category';
  }
  if (termFilter.type === FILTER_TYPE.OWNERS) {
    return 'owner';
  }
  return 'search';
};

export const getPicklistOptions = (title, termCounts) => {
  const termValues = termCounts?.[title].values;
  return Object.keys(termValues || {})
    .sort((a, b) => termValues[b] - termValues[a])
    .map((key) => ({ id: key, label: key }));
};
