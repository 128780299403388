import React from 'react';

export default function NumberPaymentTerm() {
  return (
    <>
      <div className="search-page-from-to-container-sub">
        <label>From:</label>
        <input type="text" />
      </div>
      <div className="search-page-from-to-container-sub">
        <label>To:</label>
        <input type="text" />
      </div>
    </>
  );
}
