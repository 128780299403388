import React from 'react';

export default function DatePaymentTerm() {
  return (
    <div className="search-page-date-container">
      <div className="search-page-date-inputs">
        <div className="search-page-from-to-container-sub">
          <label>From:</label>
          <input type="date" />
        </div>
        <div className="search-page-from-to-container-sub">
          <label>To:</label>
          <input type="date" />
        </div>
      </div>
    </div>
  );
}
