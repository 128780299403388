import React, { useState, useMemo, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import './style.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import Api from '../../../axios/customAxios'; // Import your custom Axios instance
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import EmptyTable from '../../common/aggrid/EmptyTable';
import 'react-toastify/dist/ReactToastify.css';

const handleNotification = (message, isSuccess) => {
  if (isSuccess) {
    toast.success(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  } else {
    toast.error(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  }
};

const getTimestamp = () => {
  const now = new Date();
  return now
    .toISOString()
    .replace(/[:\-T.]/g, '')
    .slice(0, 14);
};

const downloadFile = async (url, filename) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);

    const sanitizedFilename = `${filename}`;

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = sanitizedFilename;
    link.click();

    window.URL.revokeObjectURL(blobUrl);
    handleNotification('Successfully Downloaded File(s)!', true);
  } catch (error) {
    console.error('Error downloading file:', error);
    handleNotification(
      'File(s) could not be downloaded. Please try again!',
      false
    );
  }
};

const zipFiles = async (fileData, currentPath) => {
  const zip = new JSZip();
  const zipFilename = `yellowpad_${getTimestamp()}.zip`;

  for (const file of fileData) {
    const filePath = `${currentPath}${file.name}`;

    if (file.type === 'FOLDER') {
      zip.folder(file.name);
    } else {
      const username = localStorage.getItem('username');

      try {
        const userId = localStorage.getItem('userId');
        let customer;
        if (userId === 'be4030b1-c840-4164-8e5b-ba944f5399c3')
          customer = 'Cresta';
        else customer = 'Lucid';
        const response = await Api.getFile(filePath, username, customer);

        if (response && response.s3url) {
          const fileUrl = response.s3url.split('?')[0];
          const fileName = file.name;

          const fileBlob = await fetch(fileUrl).then((res) => res.blob());
          zip.file(fileName, fileBlob);

          const content = await zip.generateAsync({ type: 'blob' });
          saveAs(content, zipFilename);
        }
      } catch (error) {
        console.error(`Error fetching file for zipping: ${filePath}`, error);
        handleNotification(
          'Zipped File(s) could not be downloaded. Please try again!',
          false
        );
      }
    }
  }
};

const isFile = (pathSegment) => pathSegment.includes('.');

const getIconForType = (type) => {
  switch (type) {
    case 'PDF':
      return 'picture_as_pdf';
    case 'TXT':
      return 'description';
    case 'XLS':
      return 'table_chart';
    case 'MP3':
    case 'WAV':
      return 'audiotrack';
    case 'FOLDER':
      return 'folder';
    default:
      return 'insert_drive_file';
  }
};

const getType = (name) => {
  if (isFile(name)) {
    return name.split('.').pop().toUpperCase();
  }

  return 'FOLDER';
};

const formatSize = (size) => {
  if (size === undefined || size === null || size === '-') return '-';
  if (size < 1024) return `${size} B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;

  return `${(size / (1024 * 1024)).toFixed(2)} MB`;
};

const Trash = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const gridApiRef = useRef(null);

  const [currentPath, setCurrentPath] = useState('/');
  const [fileData, setFileData] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [moveDirectoryText, setMoveDirectoryText] = useState('');
  const [showMovePopup, setShowMovePopup] = useState(false);
  const navigate = useNavigate();
  const path = decodeURIComponent(searchParams.get('didUpdate'));

  useEffect(() => {
    const encodedPath = encodeURIComponent(currentPath);

    navigate(`?currentPath=${encodedPath}`, { replace: true });

    if (path === 'true') {
      searchParams.delete('didUpdate');
    }

    fetchFiles();

    window.addEventListener('error', (e) => {
      if (e.message.startsWith('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

    // eslint-disable-next-line
  }, [currentPath, navigate, path]);

  const fetchFiles = async () => {
    try {
      const response = await Api.getFileSystemFiles('/Trash', true);

      if (response && response.data) {
        const { folders, files } = response.data;

        const formattedData = [
          ...folders.map((folder) => ({
            name: folder.name,
            owner: folder.owner,
            email: folder.email,
            created: folder.created,
            filePath: [folder.name],
            type: 'FOLDER',
            size: '-',
          })),
          ...files.map((file) => ({
            name: file.name,
            filePath: [file.name],
            type: getType(file.name),
            owner: file.owner || 'N/A',
            email: file.email || 'N/A',
            created: file.created,
            size: file.size || '-',
            checklistId: file.checklistId,
            s3url: file.s3url,
          })),
        ];

        setFileData(formattedData);
      } else {
        setFileData([]);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
      handleNotification('Failed to fetch files!', false);
      setFileData([]);
    }
  };

  const columnDefs = useMemo(
    () => [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: 'Name',
        field: 'name',
        flex: 1,
        filter: true,
        sortable: true,
        cellClass: 'trashbin-ag-row-name',
        autoHeight: true,
      },
      {
        headerName: 'Type',
        field: 'type',
        cellRenderer: (params) => {
          const icon = getIconForType(params.value);
          return (
            <div className="trashbin-ag-type-icon">
              <i className="material-icons trashbin-ag-icon">{icon}</i>
            </div>
          );
        },
        minWidth: 100,
        filter: true,
        sortable: true,
        cellClass: 'trashbin-ag-row',
      },
      {
        headerName: 'Owner',
        field: 'owner',
        flex: 1,
        filter: true,
        sortable: true,
        cellClass: 'trashbin-ag-row',
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 1,
        filter: true,
        cellClass: 'trashbin-ag-row',
      },
      {
        headerName: 'Created',
        field: 'created',
        flex: 1,
        filter: true,
        sortable: true,
        cellClass: 'trashbin-ag-row',
        valueFormatter: (params) => {
          const date = new Date(params.value);
          return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
        },
      },
      {
        headerName: 'Size',
        field: 'size',
        flex: 1,
        filter: true,
        sortable: true,
        cellClass: 'trashbin-ag-row',
        valueFormatter: (params) => formatSize(params.value),
      },
    ],
    []
  );

  const handleDownloadClick = async () => {
    if (selectedFiles.length === 0) return;

    const selectedFolders = selectedFiles.filter(
      (file) => file.type === 'FOLDER'
    );
    const selectedFileNames = selectedFiles.filter(
      (file) => file.type !== 'FOLDER'
    );

    if (selectedFileNames.length === 1 && selectedFolders.length === 0) {
      const file = selectedFileNames[0]?.s3url;

      try {
        if (file) {
          downloadFile(file, selectedFileNames[0]?.name);
        } else {
          console.error('Error: No data returned from API for file download.');
          handleNotification(
            'File(s) could not be downloaded. Please try again!',
            false
          );
        }
      } catch (error) {
        console.error('Error downloading file:', error);
        handleNotification(
          'File(s) could not be downloaded due to an error!',
          false
        );
      }
    } else {
      await zipFiles(selectedFiles, currentPath);
    }
  };

  const handleMoveClick = () => {
    if (selectedFiles.length > 0) setShowMovePopup(true);
  };

  const handleCancelMove = () => {
    setShowMovePopup(false);
    setMoveDirectoryText('');
  };

  const handleConfirmMove = async () => {
    if (moveDirectoryText !== '') {
      try {
        const filePaths = selectedFiles.map(
          (file) => `${currentPath}${file?.name}`
        );

        const promises = filePaths.map((filePath) =>
          Api.moveFilesystemFile(filePath, moveDirectoryText)
        );

        await Promise.all(promises);
      } catch (error) {
        console.error('Error moving files to directory:', error);
        handleNotification(
          `Failed to move ${selectedFiles.length} content to New Directory!`,
          false
        );
      } finally {
        handleNotification(
          `Moved ${selectedFiles.length} content to New Directory!`,
          true
        );
        setShowMovePopup(false);
        setMoveDirectoryText('');
        navigate(
          `/dashboard/filesystem?currentPath=${currentPath}&didUpdate=true`
        );
      }
    }
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();

    setSelectedCount(selectedRows.length);
    setSelectedFiles(selectedRows.map((row) => row));
  };

  const onRowDoubleClicked = async (params) => {
    const { name, type } = params.data;

    if (type === 'FOLDER') {
      setCurrentPath(`${currentPath}${name}/`);
    } else {
      const checklistId = params?.data?.checklistId;
      window.open(`/dashboard/worktable/${checklistId}`, '_blank');
    }
  };

  const renderMovePopup = () => {
    if (showMovePopup) {
      return (
        <div className="basepopup-trashbin-overlay">
          <div className="trashbin-trash-popup">
            <div className="trashbin-trash-popup-content">
              <div className="trashbin-trash-popup-title">Restore Content</div>
              <div className="trashbin-trash-popup-files">
                <div className="trashbin-trash-popup-files-title">
                  Content to Restore
                </div>
                <div className="trashbin-trash-popup-file-list">
                  {selectedFiles.map((file, index) => (
                    <div key={index} className="trashbin-trash-popup-file-item">
                      {`${currentPath}${file.name}`}
                    </div>
                  ))}
                </div>
              </div>
              <div className="trashbin-trash-popup-confirm">
                <div className="trashbin-trash-popup-confirm-title">
                  Type Path of Destination Directory:
                </div>
                <input
                  type="text"
                  value={moveDirectoryText}
                  onChange={(e) => setMoveDirectoryText(e.target.value)}
                  className="trashbin-trash-popup-input"
                  placeholder="example: /folder/subfolder"
                />
              </div>
              <div className="trashbin-trash-popup-actions">
                <button
                  onClick={handleCancelMove}
                  className="trashbin-trash-popup-cancel-button"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmMove}
                  className="trashbin-trash-popup-confirm-button"
                  disabled={moveDirectoryText === ''}
                >
                  Restore to Directory
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleCurrentPath = (index) => {
    if (index === 'trash') {
      setCurrentPath('/');
    } else {
      setCurrentPath(
        '/' +
          currentPath
            .split('/')
            .filter(Boolean)
            .slice(0, index + 1)
            .join('/') +
          '/'
      );
    }
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;

    const savedState = localStorage.getItem('columnState-Trash');
    if (savedState) {
      gridApiRef.current.applyColumnState({
        state: JSON.parse(savedState),
        applyOrder: true,
      });
    }
  };

  const saveColumnState = () => {
    if (gridApiRef.current) {
      const columnState = gridApiRef.current.getColumnState();
      if (columnState) {
        localStorage.setItem('columnState-Trash', JSON.stringify(columnState));
      }
    } else {
      console.error('Grid API is not available yet.');
    }
  };

  return (
    <div className="trashbin-ag-wrapper">
      <ToastContainer />

      <div className="trashbin-ag-title">
        Trash <i className="material-icons">delete</i>
      </div>

      <div className="trashbin-ag-toolbar">
        <span>{selectedCount} selected</span>
        <button
          className="trashbin-ag-toolbar-button"
          disabled={selectedCount === 0}
          onClick={handleDownloadClick}
        >
          <div className="trashbin-ag-toolbar-button-icon">
            <i className="material-icons">download</i>
          </div>
          <div className="trashbin-ag-toolbar-button-text">Download</div>
        </button>
        <button
          className="trashbin-ag-toolbar-button"
          disabled={selectedCount === 0}
          onClick={handleMoveClick}
        >
          <div className="trashbin-ag-toolbar-button-icon">
            <i className="material-icons">restore</i>
          </div>
          <div className="trashbin-ag-toolbar-button-text">Restore</div>
        </button>
      </div>

      <div className="trashbin-ag-breadcrumb">
        <span
          onClick={() => handleCurrentPath('trash')}
          className="trashbin-ag-breadcrumb-item"
        >
          Trash
        </span>
        {currentPath
          .split('/')
          .filter(Boolean)
          .map((folder, index) => (
            <span
              key={index}
              onClick={() => handleCurrentPath(index)}
              className="trashbin-ag-breadcrumb-item"
            >
              {' / '}
              {folder}
            </span>
          ))}
      </div>

      <div
        className="ag-theme-alpine"
        style={{ height: 'calc(100vh - 130px)' }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          rowData={fileData}
          columnDefs={columnDefs}
          rowSelection="multiple"
          onRowDoubleClicked={onRowDoubleClicked}
          animateRows={true}
          onSelectionChanged={onSelectionChanged}
          suppressRowClickSelection={true}
          onColumnMoved={saveColumnState}
          onColumnVisible={saveColumnState}
          noRowsOverlayComponent={EmptyTable}
          noRowsOverlayComponentParams={{
            noRowsMessage: 'This Directory is Empty!',
          }}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivotMode: true,
                  suppressPivots: true,
                },
              },
            ],
          }}
        />
      </div>
      {renderMovePopup()}
    </div>
  );
};

export default Trash;
