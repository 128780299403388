import React from 'react';
import { FILTER_OPERATOR, FILTER_TYPE } from '../../../constants';
import NumberPaymentTerm from './Filters/PaymentTerms/NumberPaymentTerm';
import InTopicPaymentTerm from './Filters/PaymentTerms/InTopicPaymentTerm';
import MonetaryAmountPaymentTerm from './Filters/PaymentTerms/MonetaryAmountPaymentTerm';
import InValuePaymentTerm from './Filters/PaymentTerms/InValuePaymentTerm';
import DatePaymentTerm from './Filters/PaymentTerms/DatePaymentTerm';
import ChecklistFilter from './Filters/ChecklistFilter';
import InValueConfidentiality from './Filters/Confidentiality/InValueConfidentiality';
import TermIncluded from './Filters/TermIncluded';
import { getPicklistOptions } from '../../../utils/portfolioIntelligence';

export default function FilterPanel({
  selectedFilters,
  handleRemoveFilter,
  onOperatorChange,
  ownersOptions,
  onOwnerChecked,
  categoriesOptions,
  onCategoryChecked,
  onExcludeChange,
  onTermIncludeChange,
  termCounts,
  onPicklistChecked,
}) {
  // { [FILTER_TYPE]: React.Node }
  const FilterComponentMap = {
    [undefined]: null,
    [FILTER_TYPE.PAYMENT_DATE]: DatePaymentTerm,
    [FILTER_TYPE.PAYMENT_NUMBER]: NumberPaymentTerm,
    [FILTER_TYPE.PAYMENT_IN_VALUE]: InValuePaymentTerm,
    [FILTER_TYPE.TERM_PICKLIST]: ({ title }) => (
      <ChecklistFilter
        title={title}
        options={getPicklistOptions(title, termCounts)}
        optionChecked={(option, termKey) =>
          selectedFilters
            .find(
              (filter) =>
                filter.type === FILTER_TYPE.TERM_PICKLIST &&
                filter.title === termKey
            )
            ?.value.includes(option.id)
        }
        onCheckOption={onPicklistChecked}
      />
    ),
    [FILTER_TYPE.PAYMENT_PICKLIST]: () => (
      <ChecklistFilter
        optionChecked={(option) =>
          selectedFilters
            .find((filter) => filter.type === FILTER_TYPE.PAYMENT_PICKLIST)
            ?.value.includes(option.id)
        }
        options={[
          { id: 1, label: 'Blue' },
          { id: 2, label: 'Green' },
          { id: 3, label: 'Yellow' },
        ]}
      />
    ),
    [FILTER_TYPE.PAYMENT_MONETARY_AMOUNT]: MonetaryAmountPaymentTerm,
    [FILTER_TYPE.PAYMENT_IN_TOPIC]: InTopicPaymentTerm,
    [FILTER_TYPE.CONFIDENTIALITY_IN_VALUE]: InValueConfidentiality,
    [FILTER_TYPE.CATEGORIES]: () => (
      <ChecklistFilter
        optionChecked={(option) =>
          selectedFilters
            .find((filter) => filter.type === FILTER_TYPE.CATEGORIES)
            ?.value.includes(option.id)
        }
        options={categoriesOptions}
        onCheckOption={onCategoryChecked}
      />
    ),
    [FILTER_TYPE.OWNERS]: () => (
      <ChecklistFilter
        optionChecked={(option) =>
          selectedFilters
            .find(
              (selectedFilter) => selectedFilter.type === FILTER_TYPE.OWNERS
            )
            ?.value.includes(option.id)
        }
        options={ownersOptions.map((owner) => ({
          id: owner.id,
          label: owner.email,
        }))}
        onCheckOption={onOwnerChecked}
      />
    ),
    [FILTER_TYPE.TERM_INCLUDE]: ({ ...props }) => (
      <TermIncluded
        optionChecked={(termKey, contains) =>
          selectedFilters.find(
            (selectedFilter) =>
              selectedFilter.type === FILTER_TYPE.TERM_INCLUDE &&
              selectedFilter.title === termKey
          )?.value === contains
        }
        onCheckOption={onTermIncludeChange}
        {...props}
      />
    ),
  };

  if (selectedFilters.length === 0) {
    return <div className="search-page-filters-title">No terms selected..</div>;
  }
  return selectedFilters.map((filter, index) => {
    const FilterComponent = FilterComponentMap[filter.type];

    if (!filter.type) return null;
    return (
      <div key={`filter-${index}`} className="search-page-filter-box-main">
        <div className="search-page-filter-box-sub">
          <div className="search-page-filter-title">
            <div className="search-page-filter-title-text">{filter.title}</div>
            <button
              className="search-page-filter-close-button"
              onClick={() => handleRemoveFilter(index)}
            >
              x
            </button>
          </div>
          <div className="search-page-from-to-container-main">
            <FilterComponent title={filter.title} />
          </div>
          <div className="search-page-excludes-label">
            <div className="search-page-excludes-label-text">
              <input
                onChange={(e) => onExcludeChange(index, e.target.checked)}
                type="checkbox"
              />
              Exclude
            </div>
          </div>
        </div>
        {index >= 0 &&
          selectedFilters.length > 1 &&
          index !== selectedFilters.length - 1 && (
            <div className="search-page-filter-switch">
              <label className="search-page-toggle-label">AND</label>
              <label className="search-page-switch">
                <input
                  onChange={(e) =>
                    onOperatorChange(
                      index,
                      e.target.checked
                        ? FILTER_OPERATOR.OR
                        : FILTER_OPERATOR.AND
                    )
                  }
                  type="checkbox"
                />
                <span className="search-page-slider"></span>
              </label>
              <label className="search-page-toggle-label">OR</label>
            </div>
          )}
      </div>
    );
  });
}
