import React from 'react';

export default function TermIncluded({
  title: termKey,
  optionChecked,
  onCheckOption,
}) {
  return (
    <div className="search-page-picklist-label-main">
      <input
        type="radio"
        id={`${termKey}-contains`}
        name={termKey}
        value="true"
        checked={optionChecked(termKey, true)}
        onChange={() => onCheckOption?.(termKey, true)}
      />
      <label htmlFor={`${termKey}-contains`}>Contains</label>
      <input
        type="radio"
        id={`${termKey}-not-contains`}
        name={termKey}
        value="false"
        checked={optionChecked(termKey, false)}
        onChange={() => onCheckOption?.(termKey, false)}
      />
      <label htmlFor={`${termKey}-not-contains`}>Does Not Contain</label>
    </div>
  );
}
