import React from 'react';

export default function MonetaryAmountPaymentTerm() {
  return (
    <>
      <div className="search-page-monetary-container">
        <label>Currency: </label>
        <select>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
        </select>
      </div>
      <div className="search-page-from-to-container-sub">
        <label>From:</label>
        <input type="text" />
      </div>
      <div className="search-page-from-to-container-sub">
        <label>To:</label>
        <input type="text" />
      </div>
    </>
  );
}
