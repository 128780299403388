import React from 'react';

export default function InValueConfidentiality() {
  return (
    <>
      <div className="search-page-text-container">
        <label>String: </label>
        <input className="search-page-keyword-input-filter" type="text" />
      </div>
    </>
  );
}
