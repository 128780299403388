import React from 'react';

export default function InTopicPaymentTerm() {
  return (
    <div className="search-page-clause-container">
      <label>Keyword: </label>
      <input className="search-page-keyword-input-filter" type="text" />
    </div>
  );
}
