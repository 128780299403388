import { useEffect, useState } from 'react';

function useFetch(axiosRequestFn, onCompleted) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const { data } = await axiosRequestFn();
        setData(data);
        onCompleted?.(data);
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [axiosRequestFn, onCompleted]);

  return { data, loading, error };
}

export default useFetch;
