export const configs = {
  api_url: process.env.REACT_APP_API_URL || '/api',
};

export const PERMISSION = {
  OWNER: 'OWNER',
  FULL_ACCESS: 'FULL_ACCESS',
  READ_ONLY: 'READ_ONLY',
};

export const VALID_PERMS = Object.values(PERMISSION);

export const PERMISSION_PRECEDENCE = {
  OWNER: 3,
  FULL_ACCESS: 2,
  READ_ONLY: 1,
};

export const FILTER_TYPE = {
  PAYMENT_DATE: 'PAYMENT_DATE',
  PAYMENT_NUMBER: 'PAYMENT_NUMBER',
  PAYMENT_IN_VALUE: 'PAYMENT_IN_VALUE',
  PAYMENT_PICKLIST: 'PAYMENT_PICKLIST',
  PAYMENT_MONETARY_AMOUNT: 'PAYMENT_MONETARY_AMOUNT',
  PAYMENT_IN_TOPIC: 'PAYMENT_IN_TOPIC',
  CONFIDENTIALITY_IN_VALUE: 'CONFIDENTIALITY_IN_VALUE',
  CATEGORIES: 'CATEGORIES',
  OWNERS: 'OWNERS',
  TERM_INCLUDE: 'TERM_INCLUDE',
  TERM_PICKLIST: 'TERM_PICKLIST',
};

export const FILTER_OPERATOR = {
  AND: 'AND',
  OR: 'OR',
};

export const DOCUMENT_CATEGORY = {
  MA: 'ma',
  AGREEMENT: 'agreement',
  NDA: 'nda',
  MSA: 'msa',
  VENDOR_CONTRACT: 'vc',
};
