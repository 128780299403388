import React from 'react';

export default function InValuePaymentTerm() {
  return (
    <div className="search-page-text-container">
      <label>Keyword: </label>
      <input className="search-page-keyword-input-filter" type="text" />
    </div>
  );
}
